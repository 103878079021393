import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import cookie from "js-cookie";

const baseQuery = fetchBaseQuery({
  baseUrl: "https://omap-back.osher.tech/api/v1/",
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const api = createApi({
  reducerPath: "splitApi",

  baseQuery: baseQuery,

  tagTypes: ["Service"],

  endpoints: () => ({}),
});
