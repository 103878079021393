import { Select, Card, Button } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import DeathRaionGraph from "../components/deathRaionGraph";
import RaionDiag from "../components/raionDiag";
import UluusRateTable from "../components/uluusRateTable";
import ZnoRaionTable from "../components/znoRaionTable";
// import dataList from "../data/geojson.json";
import GetRaion from "../data/raions";
import { useGetNozologyDataQuery } from "../services/service";
import { useNavigate } from "react-router-dom";
import RaionMap from "../components/raion_map";

const Raion = () => {
  const { state } = useLocation();
  const [uluus, setUluus] = useState(
    state != null ? state.raion : "Республика Саха (Якутия)"
  );
  const raion_id = state != null ? state.id : 12
  const Map = GetRaion(raion_id)
  const [period, setPeriod] = useState("2015-2019");
  const [gender, setGender] = useState("Женщины");
  const [mkb, setmkb] = useState("С19-21");

  const { data: newData, isFetching } = useGetNozologyDataQuery({
    period: period,
    gender: gender,
    mkb: mkb,
  });

  let navigate = useNavigate();

  if (isFetching) {
    return <div>Loading...</div>;
  }

  const periods = ["2005-2009", "2010-2014", "2015-2019"];
  const mkbList = [
    "С00 Злокачественные новообразования губы",
    "С00-96 Злокачественные новообразования, все локализации",
    "С01, 02 Злокачественные новообразования языка",
    "С03-06, 09 Злокачественные новообразования других и неуточненных частей полости рта",
    "С07, 08 Злокачественные новообразования больших слюнных желез",
    "С10 Злокачественные новообразования ротоглотки",
    "С11 Злокачественные новообразования носоглотки",
    "С12, 13 Злокачественные новообразования гортаноглотки",
    "С15 Злокачественные новообразования пищевода",
    "С16 Злокачественные новообразования желудка",
    "С18 Злокачественные новообразования ободочной  кишки",
    "С19-21 Злокачественные новообразования прямой кишки, ректосигмоидного соединения, ануса",
    "С22 Злокачественные новообразования печени и внутрипеченочных желчных протоков",
    "С23, 24 Злокачественные новообразования желчного пузыря и внепеченочных желчных протоков",
    "С25 Злокачественные новообразования поджелудочной железы",
    "С30, 31 Злокачественные новообразования полости носа, среднего уха, придаточных пазух",
    "С32 Злокачественные новообразования гортани",
    "С33, 34 Злокачественные новообразования трахеи, бронхов, легкого",
    "С40, 41 Злокачественные новообразования костей и суставных хрящей",
    "С43 Меланома кожи",
    "С44 Другие злокачественные новообразования кожи",
    "С50 Злокачественные новообразования молочной железы",
    "С53 Злокачественные новообразования шейки матки",
    "С54 Злокачественные новообразования тела матки",
    "С56 Злокачественные новообразования яичника",
    "С58 Злокачественные новообразования плаценты",
    "С60 Злокачественные новообразования полового члена",
    "С61 Злокачественные новообразования предстательной железы",
    "С62 Злокачественные новообразования яичка",
    "С64 Злокачественные новообразования почки",
    "С67 Злокачественные новообразования мочевого пузыря",
    "С70-72 Злокачественные новообразования головного мозга и  других отделов центральной нервной системы",
    "С73 Злокачественные новообразования щитовидной железы",
    "С81 Лимфогранулематоз",
    "С81-96 Злокачественные новообразования лимфатической и кроветворной ткани",
    "С88, 90 Множественная миелома и иммунопролиферативные новообразования",
    "С91.0 Острый лимфолейкоз",
    "С91.1-91.9 Другие лимфолейкозы (хронический, подострый и т.д.)",
    "С92.0 Острый миелолейкоз",
  ];
  const mkbCodes = [
    "С00",
    "С00-96",
    "С01, 02",
    "С03-06, 09",
    "С07, 08",
    "С10",
    "С11",
    "С12, 13",
    "С15",
    "С16",
    "С18",
    "С19-21",
    "С22",
    "С23, 24",
    "С25",
    "С30, 31",
    "С32",
    "С33, 34",
    "С40, 41",
    "С43",
    "С44",
    "С50",
    "С53",
    "С54",
    "С56",
    "С58",
    "С60",
    "С61",
    "С62",
    "С64",
    "С67",
    "С70-72",
    "С73",
    "С81",
    "С81-96",
    "С88, 90",
    "С91.0",
    "С91.1-91.9",
    "С92.0",
  ];

  const mkb10 = mkbList.map((mkbLocalization, i) => (
    <Select.Option key={mkbCodes[i]} value={mkbCodes[i]}>
      {mkbLocalization}
    </Select.Option>
  ));

  const periodsSelect = periods.map((period) => (
    <Select.Option key={period} value={period}>
      {period}
    </Select.Option>
  ))

  // for (var i = 0; i !== 35; i++) {
  //   Map.features[i].properties = Object.assign(
  //     Map.features[i].properties,
  //     newData[i]
  //   );
  // }
  console.log(raion_id)

  return (
    <div>
      <h1>
        {mkb} {newData[0]["Нозология"]}. {gender}. {period}
      </h1>
      <div style={{ display: "flex", gap: "24px", marginBottom: "10px" }}>
        <Button onClick={() => {navigate('/')}}>Назад</Button>
        <Select
          defaultValue={gender}
          onChange={(value) => {
            setGender(value);
          }}
        >
          <Select.Option value="Мужчины">Мужчины</Select.Option>
          <Select.Option value="Женщины">Женщины</Select.Option>
        </Select>
        <Select
          defaultValue={mkb}
          onChange={(value) => {
            setmkb(value);
          }}
        >
          {mkb10}
        </Select>
        <Select 
          defaultValue={period}
          onChange={(value) => {
            setPeriod(value);
          }}
        >
          {periodsSelect}
        </Select>
      </div>
      <div
        style={{
          display: "flex",
          gap: "12px",
        }}
      >
        <Card>
          <RaionMap map={{ ...Map }} />
        </Card>

        <Card style={{ flexGrow: 1 }}>
          <Card.Grid style={{ width: "100%" }} hoverable={false}>
            <ZnoRaionTable raion={uluus} mkb={mkb} gender={gender} />
          </Card.Grid>
          <Card.Grid style={{ width: "100%" }} hoverable={false}>
            <UluusRateTable raion={uluus} mkb={mkb} gender={gender} />
          </Card.Grid>
        </Card>
      </div>

      {/* По всем мкб */}
      <div
        style={{
          display: "flex",
          gap: "12px",
          marginTop: "12px",
        }}
      >
        <Card style={{ width: "50%" }}>
          <RaionDiag raion={uluus} mkb={mkb} />
        </Card>
        <Card style={{ width: "50%" }}>
          <DeathRaionGraph raion={uluus} />
        </Card>
      </div>
    </div>
  );
};

export default Raion;
