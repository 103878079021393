import { Table } from "antd";
import { useGetIndicatorDataQuery } from "../services/service";

const IndicatorTable = ({ indicator }) => {
  const { data, isFetching } = useGetIndicatorDataQuery({
    indicator: indicator,
  });
  const years = ["1990", "2000", "2005", "2018", "2019", "2020"];
  if (isFetching) {
    return <div>Loading...</div>;
  }

  const columns = [
    {
      title: "Район",
      dataIndex: "Район",
      key: "Район",
    },
    {
      title: "1990",
      dataIndex: "1990",
      key: "1990",
    },
    {
      title: "2000",
      dataIndex: "2000",
      key: "2000",
    },
    {
      title: "2005",
      dataIndex: "2005",
      key: "2005",
    },
    {
      title: "2018",
      dataIndex: "2018",
      key: "2018",
    },
    {
      title: "2019",
      dataIndex: "2019",
      key: "2019",
    },
    {
      title: "2020",
      dataIndex: "2020",
      key: "2020",
    },
  ];
  // Generates a list of uluuses
  const newData = [...data].sort((a, b) =>
    a["Район"].localeCompare(b["Район"])
  );
  const dataSource = [];

  let name = newData[0]["Район"];
  dataSource.push({
    Район: newData[0]["Район"],
  });
  dataSource[0][newData[0]["Период, год"]] = newData[0]["Показатель"];

  for (let i = 0; i < newData.length; i++) {
    if (newData[i]["Район"] !== name) {
      dataSource.push({
        Район: newData[i]["Район"],
      });
      dataSource[
        dataSource.findIndex((item2) => item2["Район"] === newData[i]["Район"])
      ][newData[i]["Период, год"]] = newData[i]["Показатель"];
      name = newData[i]["Район"];
    } else {
      dataSource[
        dataSource.findIndex((item2) => item2["Район"] === newData[i]["Район"])
      ][newData[i]["Период, год"]] = newData[i]["Показатель"];
    }
  }

  return (
    <div className="IndicatorTable">
      <h3>{indicator}</h3>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        scroll={{
          y: 500,
        }}
      />
    </div>
  );
};

export default IndicatorTable;
