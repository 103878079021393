import { AreaMap } from "@ant-design/maps";

const RaionMap = ({ map, id }) => {
  const config = {
    map: {
      type: "mapbox",
      style: "blank",
      center: [120.19382669582967, 30.258134],
      zoom: 4,
      pitch: 0,
    },
    source: {
      data: map,
      parser: {
        type: "geojson",
      },
    },
    autoFit: true,
    color: {
      field: "Показатель",
      value: [
        "rgb(51,255,51)",
        "rgb(153,255,51)",
        "rgb(255,255,51)",
        "rgb(255,153,51)",
        "rgb(255,51,51)",
      ],
      scale: {
        type: "quantile",
      },
    },
    style: {
      opacity: 1,
      stroke: "rgb(93,112,146)",
      lineWidth: 0.6,
      lineOpacity: 1,
    },
    state: {
      active: true,
    },
    label: {
      visible: true,
      field: "id",
      style: {
        fill: "#000",
        opacity: 0.8,
        fontSize: 10,
        stroke: "#fff",
        strokeWidth: 1.5,
        textAllowOverlap: false,
        padding: [5, 5],
      },
    },
    tooltip: {
      items: ["Улус", "Индикатор", "Показатель", "Период, год"],
      zIndex: 9999999999999999999999999999999,
    },
    zoom: {
      position: "bottomright",
    },
    legend: {
      position: "bottomleft",
    },
  };

  return (
    <div style={{ width: "30vw", height: "50vh" }}>
      <AreaMap {...config}/>
    </div>
  );
};

export default RaionMap;
