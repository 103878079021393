import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu, Layout } from "antd";
import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from "@ant-design/icons";
const { Sider } = Layout;

const MySider = ({ collapsed }) => {
  const navigate = useNavigate();
  const itemsList = [
    { label: "Таблица", link: "/" },
    { label: "Нозологическая форма, локализаиця", link: "/nozology" },
    { label: "Диагностика", link: "/diagnostic" },
    { label: "Первая", link: "/test" },
  ];
  const itemsComponent = itemsList.map((item, index) => {
    //  const key = String(index + 1);
    return {
      label: item.label,
      key: item.link,
    };
  });
  return (
    <Sider width={250} trigger={null} collapsible collapsed={collapsed}>
      <Menu
        mode="inline"
        defaultSelectedKeys={["1"]}
        //   defaultOpenKeys={["sub1"]}
        style={{
          height: "100%",
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #4ED4FF",
        }}
        items={itemsComponent}
        theme="dark"
        onSelect={(item) => {
          navigate(item.key);
        }}
      />
    </Sider>
  );
};

export default MySider;
