import { useState } from "react";

import { Button, Select, DatePicker, Radio, Row, Col } from "antd";

import moment from "moment/moment";

import { Leaflet2 } from "../components";

import {
  useGetDataQuery,
  useGetUluusQuery,
  useGetZoneQuery,
  useGetMkb10Query,
  useGetNozologyFormQuery,
} from "../services/service";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const { Option } = Select;

const Diagnostic = () => {
  const [colorData, setColorData] = useState([]);
  const [uluus, setUluus] = useState("");
  const [zone_code, setZoneCode] = useState("");
  const [year, setYear] = useState(2019);
  const [zone, setZone] = useState("");
  const [nozologyForm, setNozologyForm] = useState("");
  const [mkb10, setMkb10] = useState("");
  const [detectability, setDetectability] = useState("");

  const { data, isLoading } = useGetDataQuery({
    uluus: uluus,
    zone_code: zone_code,
    year: year,
    zone: zone,
    nozology_form: nozologyForm,
    //  mkb10: mkb10,
  });
  const { data: uluusData } = useGetUluusQuery();
  const { data: ZoneData } = useGetZoneQuery();
  const { data: mkb10Data } = useGetMkb10Query();
  const { data: NozologyFormData } = useGetNozologyFormQuery();

  const progressBarLabels = [
    {
      label: "Выявлено в 1 стадии",
      value: "Выявлено в 1 стадии, %",
    },
    {
      label: "Выявлено во 2 стадии",
      value: "Выявлено во 2 стадии, %",
    },
    {
      label: "Выявлено в 3 стадии",
      value: "Выявлено в 3 стадии, %",
    },
    {
      label: "Выявлено в 4 стадии",
      value: "Выявлено в 4 стадии, %",
    },
    {
      label: "Раннее выявление (1-2 стадии)",
      value: "Раннее выявление (1-2 стадии), %",
    },
    {
      label: "Активно выявленных",
      value: "% активно выявленных",
    },
    {
      label: "Диагноз подтвержден морфологически",
      value: "диагноз подтвержден морфологически, %",
    },
    {
      label: "С момента установления диагноза 5 лет и более",
      value:
        "Доля пациентов, состоящих под диспансерным наблюдением с момента установления диагноза 5 лет и более, %",
    },
  ];

  console.log(data);
  console.log(
    uluus && detectability
      ? data?.filter(
          (item2) =>
            item2["Нозологическая форма, локализация"] === detectability
        )[0]
      : ""
  );
  console.log(
    uluus && detectability
      ? data?.filter(
          (item2) =>
            item2["Нозологическая форма, локализация"] === detectability
        )[0][
          "Доля пациентов, состоящих под диспансерным наблюдением с момента установления диагноза 5 лет и более, %"
        ]
      : ""
  );

  return (
    <div style={{ width: "100%", padding: "30px 60px 30px 60px" }}>
      <div style={{ display: "flex", gap: "24px", marginBottom: "50px" }}>
        <Select
          style={{ width: 380 }}
          //  placeholder="Нозологическая форма, МКБ-10"
          onChange={(value) => {
            setDetectability(value);
          }}
          value={detectability}
        >
          <Option disabled value="">
            Нозологическая форма, МКБ-10
          </Option>
          {NozologyFormData?.map((item, index) => (
            <Option key={index} value={item}>
              {item}, {mkb10Data ? mkb10Data[index] : ""}
            </Option>
          ))}
        </Select>
        <DatePicker
          defaultValue={moment(year.toString(), "YYYY")}
          format="YYYY"
          picker="year"
          onChange={(date, dateString) => {
            setYear(dateString);
          }}
        />
      </div>
      <div style={{ display: "flex", gap: "30px" }}>
        <Leaflet2 setUluus={setUluus} data={data} colorData={colorData} />
        <div>
          <div
            style={{ borderBottom: "1px solid #666666", paddingBottom: "6px" }}
          >
            <Button
              disabled
              type="text"
              style={{
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#4ED4FF",
              }}
            >
              Выбрать все
            </Button>
            <Button
              type="text"
              style={{
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#FF4E4E",
              }}
              onClick={() => {
                setUluus("");
              }}
            >
              Убрать все
            </Button>
          </div>
          <Radio.Group
            style={{ display: "flex", gap: "30px", marginTop: "12px" }}
            value={uluus}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              {uluusData?.map((item, index) => {
                if (index > uluusData.length / 2) return;
                return (
                  <Radio
                    key={index}
                    style={{
                      margin: 0,
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "24px",
                    }}
                    value={item}
                    onChange={(value) => {
                      if (value.target.checked) {
                        setUluus(value.target.value);
                      } else {
                        setUluus("");
                      }
                    }}
                  >
                    {index + 1 + ". " + item}
                  </Radio>
                );
              })}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {uluusData?.map((item, index) => {
                if (index <= uluusData?.length / 2) return;
                return (
                  <Radio
                    key={index}
                    style={{
                      margin: 0,
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "24px",
                    }}
                    value={item}
                    onChange={(value) => {
                      if (value.target.checked) {
                        setUluus(value.target.value);
                      } else {
                        setUluus("");
                      }
                    }}
                  >
                    {index + 1 + ". " + item}
                  </Radio>
                );
              })}
            </div>
          </Radio.Group>
        </div>
      </div>
      <Row gutter={[16, 24]} style={{ marginTop: "50px" }}>
        {progressBarLabels.map((item, index) => (
          <Col key={index} span={5} offset={index === 0 || index == 4 ? 0 : 1}>
            <p
              style={{
                minHeight: "48px",
                textAlign: "center",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "24px",
                marginBottom: "16px",
              }}
            >
              {item.label}
            </p>
            {
              <CircularProgressbar
                value={
                  uluus && detectability
                    ? data?.filter(
                        (item2) =>
                          item2["Нозологическая форма, локализация"] ===
                          detectability
                      )[0][item.value]
                    : 0
                }
                text={
                  uluus && detectability
                    ? data?.filter(
                        (item2) =>
                          item2["Нозологическая форма, локализация"] ===
                          detectability
                      )[0][item.value] + "%"
                    : 0 + "%"
                }
                strokeWidth={6}
                styles={{
                  text: {
                    fontSize: 18,
                    fill: "black",
                  },
                  path: {
                    strokeLinecap: "butt",
                    transformOrigin: "center center",
                    stroke: `#4ED4FF`,
                  },
                  trail: {
                    transformOrigin: "center center",
                    stroke: "rgba(78, 212, 255, 0.4)",
                  },
                }}
              />
            }
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Diagnostic;
