import { useState } from "react";

import { Button, Select, DatePicker, Radio } from "antd";

import moment from "moment/moment";

import "chart.js/auto";
import { Chart } from "react-chartjs-2";

import { Leaflet2 } from "../components";

import { useGetDataQuery, useGetUluusQuery } from "../services/service";

const { Option } = Select;

const Nozology = () => {
  const [colorData, setColorData] = useState([]);
  const [uluus, setUluus] = useState("");
  const [year, setYear] = useState(2019);
  const [detectability, setDetectability] = useState("");

  const { data, isLoading } = useGetDataQuery({
    uluus: uluus,
    year: year,
  });
  const { data: uluusData } = useGetUluusQuery();

  return (
    <div style={{ width: "100%", padding: "30px 60px 30px 60px" }}>
      <div style={{ display: "flex", gap: "24px", marginBottom: "50px" }}>
        <Select
          style={{ width: 380 }}
          placeholder="Выявляемость, установления"
          onChange={(value) => {
            setDetectability(value);
          }}
        >
          <Option value="Выявлено в 1 стадии, %">Выявлено в 1 стадии, %</Option>
          <Option value="Выявлено во 2 стадии, %">
            Выявлено во 2 стадии, %
          </Option>
          <Option value="Выявлено в 3 стадии, %">Выявлено в 3 стадии, %</Option>
          <Option value="Выявлено в 4 стадии, %">Выявлено в 4 стадии, %</Option>
          <Option value="Раннее выявление (1-2 стадии), %">
            Раннее выявление (1-2 стадии), %
          </Option>
          <Option value="% активно выявленных">% активно выявленных</Option>
          <Option value="диагноз подтвержден морфологически, %">
            диагноз подтвержден морфологически, %
          </Option>
          <Option value="Доля пациентов, состоящих под диспансерным наблюдением с момента установления диагноза 5 лет и более, %">
            с момента установления диагноза 5 лет и более
          </Option>
        </Select>
        <DatePicker
          defaultValue={moment(year.toString(), "YYYY")}
          format="YYYY"
          picker="year"
          onChange={(date, dateString) => {
            setYear(dateString);
          }}
        />
      </div>
      <div style={{ display: "flex", gap: "30px" }}>
        <Leaflet2 setUluus={setUluus} data={data} colorData={colorData} />
        <div>
          <div
            style={{ borderBottom: "1px solid #666666", paddingBottom: "6px" }}
          >
            <Button
              disabled
              type="text"
              style={{
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#4ED4FF",
              }}
            >
              Выбрать все
            </Button>
            <Button
              type="text"
              style={{
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#FF4E4E",
              }}
              onClick={() => {
                setUluus("");
              }}
            >
              Убрать все
            </Button>
          </div>
          <Radio.Group
            style={{ display: "flex", gap: "30px", marginTop: "12px" }}
            value={uluus}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              {uluusData?.map((item, index) => {
                if (index > uluusData.length / 2) return;
                return (
                  <Radio
                    key={index}
                    style={{
                      margin: 0,
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "24px",
                    }}
                    value={item}
                    onChange={(value) => {
                      if (value.target.checked) {
                        setUluus(value.target.value);
                      } else {
                        setUluus("");
                      }
                    }}
                  >
                    {index + 1 + ". " + item}
                  </Radio>
                );
              })}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {uluusData?.map((item, index) => {
                if (index <= uluusData?.length / 2) return;
                return (
                  <Radio
                    key={index}
                    style={{
                      margin: 0,
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "24px",
                    }}
                    value={item}
                    onChange={(value) => {
                      if (value.target.checked) {
                        setUluus(value.target.value);
                      } else {
                        setUluus("");
                      }
                    }}
                  >
                    {index + 1 + ". " + item}
                  </Radio>
                );
              })}
            </div>
          </Radio.Group>
        </div>
      </div>
      <div
        style={{
          padding: "20px",
        }}
      >
        <Chart
          type="bar"
          width={250}
          height={100}
          options={{
            title: {
              display: true,
              text: "fsdfsdf",
              fontSize: 15,
            },
            legend: {
              display: true,
              position: "top",
            },
          }}
          data={{
            labels: uluus !== "" ? data?.map((item, index) => index + 1) : [],
            datasets: [
              {
                data: uluus ? data?.map((item) => item[detectability]) : [],
                label: detectability,
                backgroundColor: "#4ED4FF",
                fill: true,
              },
            ],
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          marginTop: "50px",
          paddingLeft: "60px",
        }}
      >
        {uluus !== "" ? (
          data?.map((item, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              <p
                style={{
                  width: "20px",
                  margin: 0,
                  marginRight: "8px",
                }}
              >
                {index + 1}
              </p>
              <p
                style={{
                  margin: 0,
                  marginRight: "20px",
                }}
              >
                {item["Нозологическая форма, локализация"]}
              </p>
              <p
                style={{
                  margin: 0,
                  marginRight: "20px",
                  color: "#4ED4FF",
                }}
              >
                {item["Код по МКБ-10"]}
              </p>
              <p
                style={{
                  margin: 0,
                  color: "#FF4E4E",
                }}
              >
                {item[detectability]}%
              </p>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Nozology;
