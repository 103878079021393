import React, { useState, useEffect } from "react";

import { useGetNewDataQuery } from "../services/service";
import { RaionsMap } from "../components";
import dataList from "../data/geojson.json";
import { Select, Row, Col } from "antd";
import DemoTable from "../components/demoTable";
import IndicatorTable from "../components/indicatorTable";

const Map = () => {
  const [year, setYear] = useState("1990");
  const [indicator, setIndicator] = useState(
    "Естественный прирост на 1000 населения"
  );

  const { data: newData, isFetching } = useGetNewDataQuery({
    period: year,
    indicator: indicator,
  });

  // const periods = ["2005-2009", "2010-2014", "2015-2019"];

  const years = [
    "1990",
    "1991",
    "1992",
    "1993",
    "1994",
    "1995",
    "1996",
    "1997",
    "1998",
    "1999",
    "2000",
    "2001",
    "2002",
    "2003",
    "2004",
    "2005",
    "2006",
    "2007",
    "2008",
    "2009",
    "2010",
    "2011",
    "2012",
    "2013",
    "2014",
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
  ];

  if (isFetching) {
    return <div>Loading...</div>;
  }

  for (var i = 0; i !== 35; i++) {
    dataList.features[i].properties = Object.assign(
      dataList.features[i].properties,
      newData[i]
    );
  }

  // const PeriodOptions = periods.map((period) => (
  //     <Select.Option className="ButtonYear" key={period} value={period}>
  //         {period}
  //     </Select.Option>
  // ));

  const YearOptions = years.map((year) => (
    <Select.Option className="ButtonYear" key={year} value={year}>
      {year}
    </Select.Option>
  ));

  return (
    <div style={{ height: "100vh", width: "100vw", margin: 24 }}>
      <div style={{ display: "flex", gap: "24px", marginBottom: "10px" }}>
        <Select
          defaultValue={indicator}
          onChange={(value) => {
            setIndicator(value);
          }}
        >
          {/* <Select.Option value="Выбросы в атмосферу загрязняющих веществ, отходящих от стационарных источников (тонн)">
            Выбросы в атмосферу загрязняющих веществ, отходящих от стационарных
            источников (тонн)
          </Select.Option>
          <Select.Option value="Диагноз ЗНО подтвержден морфологически, %">
            Диагноз ЗНО подтвержден морфологически, %
          </Select.Option>
          <Select.Option value="Доля пациентов с ЗНО, состоящих под диспансерным наблюдением с момента установления диагноза 5 лет и более, %">
            Доля пациентов с ЗНО, состоящих под диспансерным наблюдением с
            момента установления диагноза 5 лет и более, %
          </Select.Option>
          <Select.Option value="Доля активно выявленных ЗНО, %">
            Доля активно выявленных ЗНО, %
          </Select.Option>
          <Select.Option value="Доля населения моложе 15 лет, %. ВПН 2010">
            Доля населения моложе 15 лет, %. ВПН 2010
          </Select.Option>
          <Select.Option value="Доля населения старше трудоспособного возраста, %. ВПН 2010">
            Доля населения старше трудоспособного возраста, %. ВПН 2010
          </Select.Option>
          <Select.Option value="Доля трудоспособного населеня, %">
            Доля трудоспособного населеня, %
          </Select.Option>
          <Select.Option value="Другие национальности, % (ВПН 2010)">
            Другие национальности, % (ВПН 2010)
        </Select.Option> */}
          <Select.Option value="Естественный прирост на 1000 населения">
            Естественный прирост на 1000 населения
          </Select.Option>
          {/*  <Select.Option value="заболеваемость 100 тыс. населения">
            заболеваемость 100 тыс. населения
          </Select.Option>
          <Select.Option value="Заболеваемость 100 тыс. населения">
            Заболеваемость 100 тыс. населения
          </Select.Option>
          <Select.Option value="Заболеваемость на 1000 населения всех возрастов">
            Заболеваемость на 1000 населения всех возрастов
          </Select.Option>
          <Select.Option value="Заболеваемость сахарным диабетом 1 типа на 1000 населения">
            Заболеваемость сахарным диабетом 1 типа на 1000 населения
          </Select.Option>
          <Select.Option value="Заболеваемость сахарным диабетом 2 типа на 1000">
            Заболеваемость сахарным диабетом 2 типа на 1000
          </Select.Option>
          <Select.Option value="ЗНО выявлено в 1 стадии, %">
            ЗНО выявлено в 1 стадии, %
          </Select.Option>
          <Select.Option value="ЗНО выявлено в 3 стадии, %">
            ЗНО выявлено в 3 стадии, %
          </Select.Option>
          <Select.Option value="ЗНО выявлено в 4 стадии, %">
            ЗНО выявлено в 4 стадии, %
          </Select.Option>
          <Select.Option value="ЗНО выявлено во 2 стадии, %">
            ЗНО выявлено во 2 стадии, %
          </Select.Option>
          <Select.Option value="КМНС, % (ВПН 2010)">
            КМНС, % (ВПН 2010)
          </Select.Option>
          <Select.Option value="Количество врачей на 10000 населения">
            Количество врачей на 10000 населения
          </Select.Option>
          <Select.Option value="Количество коек на 10000 населения">
            Количество коек на 10000 населения
          </Select.Option>
          <Select.Option value="Медиана среднего возраста населения, лет">
            Медиана среднего возраста населения, лет
          </Select.Option>
          <Select.Option value="Младенческая смертность на 1000 родившихся живыми">
            Младенческая смертность на 1000 родившихся живыми
          </Select.Option>
          <Select.Option value="Мощность амбулаторно-поликлинической сети на 10 000 населения">
            Мощность амбулаторно-поликлинической сети на 10 000 населения
          </Select.Option>
          <Select.Option value="Ожидаемая продолжительность жизни при рождении, лет">
            Ожидаемая продолжительность жизни при рождении, лет
          </Select.Option>
          <Select.Option value="Раннее выявление ЗНО (1-2 стадии), %">
            Раннее выявление ЗНО (1-2 стадии), %
          </Select.Option>*/}
          <Select.Option value="Рождаемость на 1000 населения">
            Рождаемость на 1000 населения
          </Select.Option>
          {/* <Select.Option value="Русские, % (ВПН 2010)">
            Русские, % (ВПН 2010)
          </Select.Option>
          <Select.Option value="смертность на 100 тыс. населения">
            смертность на 100 тыс. населения
          </Select.Option> */}
          <Select.Option value="Смертность на 1000 населения">
            Смертность на 1000 населения
          </Select.Option>
          {/* <Select.Option value="Смертность от болезней системы кровообращения на 100 000 населения">
            Смертность от болезней системы кровообращения на 100 000 населения
          </Select.Option>
          <Select.Option value="Смертность от внешних причин на 100 000 населения">
            Смертность от внешних причин на 100 000 населения
          </Select.Option>
          <Select.Option value="Смертность от новообразований на 100 000 населения">
            Смертность от новообразований на 100 000 населения
          </Select.Option>
          <Select.Option value="Среднегодовая численность населения">
            Среднегодовая численность населения
          </Select.Option>
          <Select.Option value="Средний возраст, лет">
            Средний возраст, лет
          </Select.Option>
          <Select.Option value="Хронический ВГВ на 100 000 населения">
            Хронический ВГВ на 100 000 населения
          </Select.Option>
          <Select.Option value="Хронический ВГС на 100 000 населения">
            Хронический ВГС на 100 000 населения
          </Select.Option>
          <Select.Option value="Частота курения, % (форма 131/у)">
            Частота курения, % (форма 131/у)
          </Select.Option> 
          <Select.Option value="Численность населения на начало года">
            Численность населения на начало года
          </Select.Option>*/}
          <Select.Option value="Численность населения на начало года, тыс. чел.">
            Численность населения на начало года, тыс. чел.
          </Select.Option>
          {/* <Select.Option value="Якуты и КМНС, % (ВПН 2010)">
            Якуты и КМНС, % (ВПН 2010)
          </Select.Option>
          <Select.Option value="Якуты, % (ВПН 2010)">
            Якуты, % (ВПН 2010)
          </Select.Option> */}
        </Select>
        <Select
          defaultValue={year}
          onChange={(value) => {
            setYear(value);
          }}
        >
          {/* <Select.OptGroup>{PeriodOptions}</Select.OptGroup> */}
          <Select.OptGroup>{YearOptions}</Select.OptGroup>
        </Select>
      </div>
      <h2>
        {indicator}. {year}г.
      </h2>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "24px",
          width: "90vw",
          justifyContent: "space-between",
        }}
      >
        <RaionsMap
          map={{ ...dataList }}
          style={{
            height: "20vh",
            width: "20vw",
          }}
        />
        <DemoTable />
      </div>
      <div style={{ marginTop: "24px" }}>
        <IndicatorTable indicator={indicator} />
      </div>
    </div>
  );
};

export default Map;
