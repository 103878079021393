import { useState, useEffect } from "react";

import { Table } from "antd";

import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  GeoJSON,
} from "react-leaflet";
import geojson from "../data/geojson.json";

const Leaflet2 = ({ setUluus, data, colorData }) => {
  const [geojsonData, setGeojsonData] = useState(geojson);
  const [openModal, setOpenModal] = useState(false);
  const position = [68.3, 134.1];

  useEffect(() => {
    document.getElementsByClassName(
      "leaflet-control-attribution"
    )[0].style.display = "none";
  }, []);

  useEffect(() => {
    if (colorData.length > 1) {
      console.log("colorData", colorData);
      let geojsonVar = { ...geojsonData };
      geojsonVar?.features?.map(
        (item) =>
          (item.properties = colorData.filter(
            (item2) => item2.Улус === item.properties.Улус
          )[0])
      );
      setGeojsonData(geojsonVar);
    } else {
      let geojsonVar = { ...geojsonData };
      geojsonVar.features?.map((item) => (item.properties.color = 0));
      setGeojsonData(geojsonVar);
    }
  }, [colorData]);

  //   console.log("geojsonData", geojsonData);

  function renderRaions(geojsonData) {
    return geojsonData?.features.map((raion, index) => {
      const onEachRaion = (raion, layer) => {
        const popupContent = `
			<div>
				<b>${raion.properties.id}. ${raion.properties.Улус}</b>	
			</div>
		`;
        layer.bindPopup(popupContent);
        layer.on({
          mouseover: (event) => {
            event.target.setStyle({
              fillColor: "grey",
              fillOpacity: "0.5",
            });
            if (!openModal) {
              layer.openPopup();
              setOpenModal(true);
            }
          },
          mouseout: (event) => {
            event.target.setStyle({
              fillColor: "red",
              fillOpacity: raion.properties.color / 100,
            });
            if (openModal) {
              layer.closePopup();
              setOpenModal(false);
            }
          },
          click: (event) => {
            setUluus(event.target.feature.properties.Улус);
          },
        });
      };

      let style = {
        color: "rgb(51, 136, 255)",
        fillColor: "red",
        fillOpacity: raion.properties.color / 100,
      };

      return (
        <GeoJSON
          key={index}
          data={raion}
          pathOptions={style}
          onEachFeature={onEachRaion}
        />
      );
    });
  }

  return (
    <div
      style={{
        width: "50%",
        height: "70vh",
      }}
    >
      <MapContainer
        className="mapContainer"
        style={{ width: "100%", height: "100%", overflow: "hidden" }}
        center={position}
        zoom={4}
        attributionControl={true}
        zoomControl={true}
        doubleClickZoom={true}
        scrollWheelZoom={true}
        dragging={true}
        animate={true}
        easeLinearity={0.35}
      >
        {renderRaions(geojsonData)}
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      </MapContainer>
    </div>
  );
};

export default Leaflet2;
