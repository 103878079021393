import { useState, useRef } from "react";
import {
  Table,
  Button,
  Checkbox,
  DatePicker,
  Menu,
  Radio,
  Space,
  message,
  Typography,
  Layout,
} from "antd";
import { BgColorsOutlined } from "@ant-design/icons";
import Leaflet2 from "../components/leaflet2";

import {
  useGetDataQuery,
  useGetUluusQuery,
  useGetZoneQuery,
  useGetMkb10Query,
  useGetNozologyFormQuery,
} from "../services/service";

import geojsonData from "../data/geojson.json";

const MyTable = () => {
  const [filteredColumn, setFilteredColumn] = useState("");
  const [colorData, setColorData] = useState([]);
  const [uluus, setUluus] = useState("");
  const [zone_code, setZoneCode] = useState("");
  const [year, setYear] = useState(2019);
  const [zone, setZone] = useState("");
  const [nozologyForm, setNozologyForm] = useState("");
  const [mkb10, setMkb10] = useState("");
  const [filteredData, setFilteredData] = useState("");

  const { data, isLoading } = useGetDataQuery({
    uluus: uluus,
    zone_code: zone_code,
    year: year,
    zone: zone,
    nozology_form: nozologyForm,
    //  mkb10: mkb10,
  });
  const { data: uluusData } = useGetUluusQuery();
  const { data: ZoneData } = useGetZoneQuery();
  const { data: mkb10Data } = useGetMkb10Query();
  const { data: NozologyFormData } = useGetNozologyFormQuery();

  const dataFilter = () => ({
    filterDropdown: ({ confirm }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <DatePicker
          onChange={(date, dateString) => {
            confirm({ closeDropdown: true });
            setYear(dateString);
          }}
          picker="year"
        />
      </div>
    ),
  });

  const colorFilter = (dataIndex) => ({
    filterDropdown: () => <div></div>,
    filterIcon: () => (
      <BgColorsOutlined
        style={{
          color: filteredColumn === dataIndex ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilterDropdownOpenChange: () => {
      if (filteredData) {
        setColorData(
          filteredColumn !== dataIndex
            ? data
                .filter(
                  (item) =>
                    item["Код по МКБ-10"] === filteredData ||
                    item["Нозологическая форма, локализация"] === filteredData
                )
                .map(
                  (item) => (item = { Улус: item.Улус, color: item[dataIndex] })
                )
            : ""
        );
        setFilteredColumn(filteredColumn !== dataIndex ? dataIndex : "");
      } else {
        message.warning("Выберите нозологическую форму или код по МКБ-10");
      }
    },
  });

  const onChange = (pagination, filters, sorter, extra) => {
    setFilteredColumn("");
    setColorData([]);
    setFilteredData(
      filters["Код по МКБ-10"]
        ? filters["Код по МКБ-10"][0]
        : filters["Нозологическая форма, локализация"]
        ? filters["Нозологическая форма, локализация"][0]
        : ""
    );
  };

  const columns = [
    {
      title: "Улус",
      dataIndex: "Улус",
      key: "Улус",
      width: 150,
      filterMultiple: false,
      filters: uluusData?.map((item) => {
        return {
          text: item,
          value: item,
        };
      }),
      onFilter: (value, record) => {
        return record.Улус.indexOf(value) === 0;
      },
      sorter: (a, b) => a["Улус"].localeCompare(b["Улус"]),
    },
    {
      title: "Зона",
      dataIndex: "Зона",
      key: "Зона",
      width: 150,
      filters: ZoneData?.map((item, index) => {
        return {
          text: item.Зона,
          value: item["код зоны"],
        };
      }),
      onFilter: (value, record) => {
        return record["код зоны"] === value;
      },
      sorter: (a, b) => a["Зона"].localeCompare(b["Зона"]),
    },
    {
      title: "Код зоны",
      dataIndex: "код зоны",
      key: "код зоны",
      width: 150,
      filters: ZoneData?.map((item) => {
        return {
          text: item["код зоны"],
          value: item["код зоны"],
        };
      }),
      onFilter: (value, record) => {
        return record["код зоны"] === value;
      },
      // record["код зоны"].indexOf(value) === 0,
      sorter: (a, b) => a["код зоны"] - b["код зоны"],
    },
    {
      title: "Год",
      dataIndex: "Год",
      key: "Год",
      width: 150,
      ...dataFilter(),
    },
    {
      title: "Нозологическая форма, локализация",
      dataIndex: "Нозологическая форма, локализация",
      key: "Нозологическая форма, локализация",
      width: 250,
      filterMultiple: false,
      filters: NozologyFormData?.map((item, index) => {
        return {
          text: item,
          value: mkb10Data ? mkb10Data[index] : "",
        };
      }),
      onFilter: (value, record) => {
        //   setMkb10(value !== mkb10 ?? mkb10);
        return record["Код по МКБ-10"].indexOf(value) === 0;
      },
      sorter: (a, b) =>
        a["Нозологическая форма, локализация"].localeCompare(
          b["Нозологическая форма, локализация"]
        ),
    },
    {
      title: "Код по МКБ-10",
      dataIndex: "Код по МКБ-10",
      key: "Код по МКБ-10",
      width: 150,
      filterMultiple: false,
      filters: mkb10Data?.map((item) => {
        return {
          text: item,
          value: item,
        };
      }),
      onFilter: (value, record) => {
        //   setMkb10(value);
        return record["Код по МКБ-10"].indexOf(value) === 0;
      },
      sorter: (a, b) => a["Код по МКБ-10"].localeCompare(b["Код по МКБ-10"]),
    },
  ];

  return (
    <div style={{ width: "100%", padding: "30px 60px 30px 60px" }}>
      <div style={{ display: "flex", gap: "30px" }}>
        <Leaflet2 setUluus={setUluus} data={data} colorData={colorData} />
        <div>
          <div
            style={{ borderBottom: "1px solid #666666", paddingBottom: "6px" }}
          >
            <Button
              disabled
              type="text"
              style={{
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#4ED4FF",
              }}
            >
              Выбрать все
            </Button>
            <Button
              type="text"
              style={{
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#FF4E4E",
              }}
              onClick={() => {
                setUluus("");
              }}
            >
              Убрать все
            </Button>
          </div>
          <Radio.Group
            style={{ display: "flex", gap: "30px", marginTop: "12px" }}
            value={uluus}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              {uluusData?.map((item, index) => {
                if (index > uluusData.length / 2) return;
                return (
                  <Radio
                    key={index}
                    style={{
                      margin: 0,
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "24px",
                    }}
                    value={item}
                    onChange={(value) => {
                      if (value.target.checked) {
                        setUluus(value.target.value);
                      } else {
                        setUluus("");
                      }
                    }}
                  >
                    {index + 1 + ". " + item}
                  </Radio>
                );
              })}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {uluusData?.map((item, index) => {
                if (index <= uluusData?.length / 2) return;
                return (
                  <Radio
                    key={index}
                    style={{
                      margin: 0,
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "24px",
                    }}
                    value={item}
                    onChange={(value) => {
                      if (value.target.checked) {
                        setUluus(value.target.value);
                      } else {
                        setUluus("");
                      }
                    }}
                  >
                    {index + 1 + ". " + item}
                  </Radio>
                );
              })}
            </div>
          </Radio.Group>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          padding: "20px",
        }}
      >
        {/* <Typography>Данные карты</Typography>
        <Button onClick={() => setUluus(null)}>Сброс</Button> */}
      </div>
      <Table
        style={{ width: "100%" }}
        dataSource={data}
        loading={isLoading}
        columns={columns}
        scroll={{ x: "max-content" }}
        onChange={onChange}
      />
    </div>
  );
};

export default MyTable;
