import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Layout } from "antd";

import { Authorization, Map, MyTable, Nozology, Diagnostic } from "./pages";

import { MySider, MyHeader } from "./components";
import Raion from "./pages/raion";

const { Content } = Layout;

const App = () => {
  const token = localStorage.getItem("token");
  const [collapsed, setCollapsed] = useState(true);
  return (
    <div>
      {token === "minzdrav_password" ? (
        <MyHeader collapsed={collapsed} setCollapsed={setCollapsed} />
      ) : (
        <></>
      )}
      <Content
        style={{
          display: "flex",
          justifyContent: token === "minzdrav_password" ? "start" : "center",
          padding: 24,
        }}
      >
        <BrowserRouter>
          {/* {token === "minzdrav_password" ? (
            <MySider collapsed={collapsed} />
          ) : (
            <></>
          )} */}
          <Routes>
            <Route
              path="/"
              element={
                token === "minzdrav_password" ? <Map /> : <Authorization />
              }
            ></Route>
            <Route
              path="/raion/details/"
              element={
                token === "minzdrav_password" ? <Raion /> : <Authorization />
              }
            ></Route>
            <Route
              path="/test"
              element={
                token === "minzdrav_password" ? <MyTable /> : <Authorization />
              }
            ></Route>
            <Route
              path="/nozology"
              element={
                token === "minzdrav_password" ? <Nozology /> : <Authorization />
              }
            ></Route>
            <Route
              path="/diagnostic"
              element={
                token === "minzdrav_password" ? (
                  <Diagnostic />
                ) : (
                  <Authorization />
                )
              }
            ></Route>
          </Routes>
        </BrowserRouter>
      </Content>
    </div>
  );
};

export default App;
