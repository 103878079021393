import { useGetDeathRaionDataQuery } from "../services/service";
import { Line } from "@ant-design/plots";

function DeathRaionGraph({ raion }) {
  const { data, isFetching } = useGetDeathRaionDataQuery({
    uluus: raion,
  });
  if (isFetching) {
    return <div>Loading...</div>;
  }
  const config = {
    data,
    padding: "auto",
    xField: "Период, год",
    yField: "Показатель",
    xAxis: {
      // type: 'timeCat',
      tickCount: 5,
    },
  };
  return (
    <div className="DeathLine">
      <h3>Смертность на 100 тыс. населения в {raion}</h3>
      <Line {...config} />
    </div>
  );
}

export default DeathRaionGraph;
