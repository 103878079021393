import { Button, Form, Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const AuthorizationForm = () => {
  const onFinish = (data) => {
    localStorage.setItem("token", data.password);
    document.location.reload();
  };

  return (
    <div style={{ width: 300, padding: "20px", backgroundColor: "slategrey" }}>
      <div
        style={{
          color: "#FFFFFF",
          marginBottom: "10px",
          fontWeight: 500,
          fontSize: 20,
        }}
      >
        Авторизация
      </div>
      <Form
        onFinish={onFinish}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          label="Пароль"
          name="password"
          placeholder="Введите пароль"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Войти
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AuthorizationForm;
