import raion1 from "./1.json";
import raion2 from "./2.json";
import raion3 from "./3.json";
import raion4 from "./4.json";
import raion5 from "./5.json";
import raion6 from "./6.json";
import raion7 from "./7.json";
import raion8 from "./8.json";
import raion9 from "./9.json";
import raion10 from "./10.json";
import raion11 from "./11.json";
import raion12 from "./12.json";
import raion13 from "./13.json";
import raion14 from "./14.json";
import raion15 from "./15.json";
import raion16 from "./16.json";
import raion17 from "./17.json";
import raion18 from "./18.json";
import raion19 from "./19.json";
import raion20 from "./20.json";
import raion21 from "./21.json";
import raion22 from "./22.json";
import raion23 from "./23.json";
import raion24 from "./24.json";
import raion25 from "./25.json";
import raion26 from "./26.json";
import raion27 from "./27.json";
import raion28 from "./28.json";
import raion29 from "./29.json";
import raion30 from "./30.json";
import raion31 from "./31.json";
import raion32 from "./32.json";
import raion33 from "./33.json";
import raion34 from "./34.json";
import raion35 from "./35.json";

function GetRaion(id) {
    switch (id) {
        case 1:
            return raion1;
        case 2:
            return raion2;
        case 3:
            return raion3;
        case 4:
            return raion4;
        case 5:
            return raion5;
        case 6:
            return raion6;
        case 7:
            return raion7;
        case 8:
            return raion8;
        case 9:
            return raion9;
        case 10:
            return raion10;
        case 11:
            return raion11;
        case 12:
            return raion12;
        case 13:
            return raion13;
        case 14:
            return raion14;
        case 15:
            return raion15;
        case 16:
            return raion16;
        case 17:
            return raion17;
        case 18:
            return raion18;
        case 19:
            return raion19;
        case 20:
            return raion20;
        case 21:
            return raion21;
        case 22:
            return raion22;
        case 23:
            return raion23;
        case 24:
            return raion24;
        case 25:
            return raion25;
        case 26:
            return raion26;
        case 27:
            return raion27;
        case 28:
            return raion28;
        case 29:
            return raion29;
        case 30:
            return raion30;
        case 31:
            return raion31;
        case 32:
            return raion32;
        case 33:
            return raion33;
        case 34:
            return raion34;
        case 35:
            return raion35;
        default:
            break;
    }
}

export default GetRaion