import { Table } from "antd";
import { useGetRaionDiagDataQuery } from "../services/service";

function RaionDiag({ raion, mkb }) {
  const indicators = [
    "Доля активно выявленных ЗНО, %",
    "Диагноз ЗНО подтвержден морфологически, %",
    "Раннее выявление ЗНО (1-2 стадии), %",
    "ЗНО выявлено в 4 стадии, %",
  ];
  const years = ["2015", "2016", "2017", "2018", "2019"];
  const { data, isFetching } = useGetRaionDiagDataQuery({
    uluus: raion,
    mkb: mkb,
  });
  if (isFetching) {
    return <div>Loading...</div>;
  }
  const dataSource = [
    {
      key: "1",
      indicator: indicators[0],
      2015: "----",
      2016: "----",
      2017: "----",
      2018: "----",
      2019: "----",
    },
    {
      key: "2",
      indicator: indicators[1],
      2015: "----",
      2016: "----",
      2017: "----",
      2018: "----",
      2019: "----",
    },
    {
      key: "3",
      indicator: indicators[2],
      2015: "----",
      2016: "----",
      2017: "----",
      2018: "----",
      2019: "----",
    },
    {
      key: "4",
      indicator: indicators[3],
      2015: "----",
      2016: "----",
      2017: "----",
      2018: "----",
      2019: "----",
    },
  ];
  const columns = [
    {
      title: "Индикатор",
      dataIndex: "indicator",
      key: "indicator",
    },
    {
      title: "2015",
      dataIndex: "2015",
      key: "2015",
    },
    {
      title: "2016",
      dataIndex: "2016",
      key: "2016",
    },
    {
      title: "2017",
      dataIndex: "2017",
      key: "2017",
    },
    {
      title: "2018",
      dataIndex: "2018",
      key: "2018",
    },
    {
      title: "2019",
      dataIndex: "2019",
      key: "2019",
    },
  ];
  for (let i = 0; i < data.length; i++) {
    if (
      indicators.includes(data[i]["Индикатор"]) &
      years.includes(data[i]["Период, год"])
    ) {
      dataSource[indicators.indexOf(data[i]["Индикатор"])][
        data[i]["Период, год"]
      ] = data[i]["Показатель"];
    }
  }
  return (
    <div className="DemoTable">
      <h3>{mkb}: диагностика</h3>
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        bordered={true}
      />
    </div>
  );
}

export default RaionDiag;
