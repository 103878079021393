import { api } from "./api";

export const Service = api.injectEndpoints({
  endpoints: (build) => ({
    getData: build.query({
      query: ({ uluus, zone_code, year, zone, nozology_form, mkb10 }) =>
        `data/${year ? "?year=" + year : ""}${uluus ? "&uluus=" + uluus : ""}${
          zone_code ? "&zone_code=" + zone_code : ""
        }${zone ? "&zone=" + zone : ""}${
          nozology_form ? "&nozology_form=" + nozology_form : ""
        }${mkb10 ? "&mkb10=" + mkb10 : ""}`,
      providesTags: ["Service"],
    }),
    getUluus: build.query({
      query: () => "uluus/",
      providesTags: ["Service"],
    }),
    getZone: build.query({
      query: () => "zone/",
      providesTags: ["Service"],
    }),
    getMkb10: build.query({
      query: () => "mkb10/",
      providesTags: ["Service"],
    }),
    getNozologyForm: build.query({
      query: () => "nozology_form/",
      providesTags: ["Service"],
    }),
    getNewData: build.query({
      query: ({period, indicator}) => `new_data?indicator=${indicator}&period=${period}`,
      providesTags: ["Service"],
    }),
    getUluusData: build.query({
      query: () => `new_data?uluus=Республика Саха (Якутия)`,
      providesTags: ["Service"],
    }),
    getIndicatorData: build.query({
      query: ({indicator}) => `new_data?indicator=${indicator}`,
      providesTags: ["Service"],
    }),
    getNozologyData: build.query({
      query: ({period, gender, mkb}) => `new_data?mkb10=${mkb}&period=${period}${gender?"&gender="+gender : ''}`,
      providesTags: ["Service"],
    }),
    getZnoRaionData: build.query({
      query: ({uluus, gender, mkb}) => `new_data?mkb10=${mkb}&uluus=${uluus}&gender=${gender}`,
      providesTags: ["Service"],
    }),
    getDeathRaionData: build.query({
      query: ({uluus}) => `new_data?mkb10=С00-96&uluus=${uluus}&indicator=смертность  на 100 тыс. населения`,
      providesTags: ["Service"],
    }),
    getRaionDiagData: build.query({
      query: ({uluus, mkb}) => `new_data?mkb10=${mkb}&uluus=${uluus}`,
      providesTags: ["Service"],
    }),
  }),
});

export const {
  useGetDataQuery,
  useGetUluusQuery,
  useGetZoneQuery,
  useGetMkb10Query,
  useGetNozologyFormQuery,
  useGetNewDataQuery,
  useGetUluusDataQuery,
  useGetIndicatorDataQuery,
  useGetNozologyDataQuery,
  useGetZnoRaionDataQuery,
  useGetDeathRaionDataQuery,
  useGetRaionDiagDataQuery,
} = Service;
