import React, { useState } from "react";

import { Drawer, Layout, Menu } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import MenuItem from "antd/lib/menu/MenuItem";

const { Header } = Layout;

const MyHeader = ({ collapsed, setCollapsed }) => {
  return (
    <Header
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "56px",
        padding: "0 20px",
        background:
          "linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #4ED4FF",
        boxShadow: "2px 2px 12px rgba(0, 0, 0, 0.25)",
      }}
    >
      <div style={{ display: "flex", gap: "28px", alignItems: "center" }}>
        {/* <img
          src="/images/menu_icon.svg"
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        /> */}
        {/* {React.createElement(
          collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            onClick: () => setCollapsed(!collapsed),
            style: { color: "#FFFFFF" },
          }
        )} */}
        <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
          <img src="/images/logo.svg" />
          <p
            style={{
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              color: "#FFFFFF",
              margin: 0,
            }}
          >
            ГИС Злокачественные новообразования в Якутии
          </p>
        </div>
      </div>
      <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
        <p
          style={{
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            color: "#FFFFFF",
            margin: 0,
          }}
        >
          Афанасьева Лена Николаевна
        </p>
        <div
          style={{
            height: "16px",
            width: "0",
            color: "#FFFFFF",
            border: "1px solid #FFFFFF",
          }}
        />
        <p
          style={{
            cursor: "pointer",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            color: "#FFFFFF",
            margin: 0,
          }}
          onClick={() => {
            localStorage.removeItem("token");
            document.location.reload();
          }}
        >
          Выйти
        </p>
      </div>
    </Header>
  );
};

export default MyHeader;
