import { Table } from "antd";
import { useGetZnoRaionDataQuery } from "../services/service";

function UluusRateTable({ mkb, gender, raion }) {
  const { Column, ColumnGroup } = Table;
  const { data, isFetching } = useGetZnoRaionDataQuery({
    mkb: mkb,
    uluus: raion,
    gender: gender,
  });
  if (isFetching) {
    return <div>Loading...</div>;
  }
  const dataSource = [
    {
      key: "1",
      raion: raion,
      po20059: data[0]["Показатель"],
      pos20059: data[0]["Стандартизованный показатель"],
      po20104: data[1]["Показатель"],
      pos20104: data[1]["Стандартизованный показатель"],
      po20159: data[2]["Показатель"],
      pos20159: data[2]["Стандартизованный показатель"],
    },
  ];
  return (
    <Table dataSource={dataSource} pagination={false} bordered={true}>
      <Column title="Район" dataIndex="raion" key="raion" />
      <ColumnGroup title="2005-2009">
        <Column
          title="грубый"
          dataIndex="po20059"
          key="po20059"
          align="center"
        />
        <Column
          title="стандартизированный"
          dataIndex="pos20059"
          key="pos20059"
          align="center"
        />
      </ColumnGroup>
      <ColumnGroup title="2010-2014">
        <Column
          title="грубый"
          dataIndex="po20104"
          key="po20104"
          align="center"
        />
        <Column
          title="стандартизированный"
          dataIndex="pos20104"
          key="pos20104"
          align="center"
        />
      </ColumnGroup>
      <ColumnGroup title="2015-2019">
        <Column
          title="грубый"
          dataIndex="po20159"
          key="po20159"
          align="center"
        />
        <Column
          title="стандартизированный"
          dataIndex="pos20159"
          key="pos20159"
          align="center"
        />
      </ColumnGroup>
    </Table>
  );
}

export default UluusRateTable;
