import { Table } from "antd";
import { useGetZnoRaionDataQuery } from "../services/service";

function ZnoRaionTable({ mkb, gender }) {
  const { Column, ColumnGroup } = Table;
  const raion = "Республика Саха (Якутия)";
  const { data, isFetching } = useGetZnoRaionDataQuery({
    mkb: mkb,
    uluus: "Республика Саха (Якутия)",
    gender: gender,
  });
  if (isFetching) {
    return <div>Loading...</div>;
  }
  if (data.length === 0) {
    return <div>Нет данных</div>
  } 
  const dataSource = [
    {
      key: "1",
      raion: raion,
      gender: gender,
      indicator: "Заболеваемость",
      po2010: data[1]["Показатель"],
      pos2010: data[1]["Стандартизованный показатель"],
      po2015: data[2]["Показатель"],
      pos2015: data[2]["Стандартизованный показатель"],
      po2019: data[3]["Показатель"],
      pos2019: data[3]["Стандартизованный показатель"],
    },
    {
      key: "2",
      raion: "",
      gender: "",
      indicator: "Смертность",
      po2010: data[5]["Показатель"],
      pos2010: data[5]["Стандартизованный показатель"],
      po2015: data[6]["Показатель"],
      pos2015: data[6]["Стандартизованный показатель"],
      po2019: data[7]["Показатель"],
      pos2019: data[7]["Стандартизованный показатель"],
    },
  ];
  return (
    <Table dataSource={dataSource} pagination={false} bordered={true}>
      <ColumnGroup title="">
        <Column title="Район" dataIndex="raion" key="raion" />
        <Column title="Пол" dataIndex="gender" key="gender" />
        <Column title="Индикатор" dataIndex="indicator" key="indicator" />
      </ColumnGroup>
      <ColumnGroup title="2010">
        <Column title="грубый" dataIndex="po2010" key="po2010" align="center" />
        <Column
          title="стандартизированный"
          dataIndex="pos2010"
          key="pos2010"
          align="center"
        />
      </ColumnGroup>
      <ColumnGroup title="2015">
        <Column title="грубый" dataIndex="po2015" key="po2015" align="center" />
        <Column
          title="стандартизированный"
          dataIndex="pos2015"
          key="pos2015"
          align="center"
        />
      </ColumnGroup>
      <ColumnGroup title="2019">
        <Column title="грубый" dataIndex="po2019" key="po2019" align="center" />
        <Column
          title="стандартизированный"
          dataIndex="pos2019"
          key="pos2019"
          align="center"
        />
      </ColumnGroup>
    </Table>
  );
}

export default ZnoRaionTable;
