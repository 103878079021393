import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { api } from "../services/api";

export const setupStore = () => {
  return configureStore({
    reducer: { [api.reducerPath]: api.reducer },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(api.middleware),
  });
};
