import { Table } from "antd";
import { useGetUluusDataQuery } from "../services/service";

const DemoTable = () => {
    const indicators = [
        "Естественный прирост на 1000 населения",
        "Рождаемость на 1000 населения",
        "Смертность на 1000 населения",
        "Численность населения на начало года, тыс. чел.",
    ];
    const years = [
        "1990",
        "2000",
        "2005",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
    ];
    const { data, isFetching } = useGetUluusDataQuery({});

    const dataSource = [
        {
            key: "1",
            indicator: "Естественный прирост на 1000 населения",
            1990: "----",
            2000: "----",
            2005: "----",
            2018: "----",
            2019: "----",
            2020: "----",
            2021: "----",
            2022: "----",
        },
        {
            key: "2",
            indicator: "Рождаемость на 1000 населения",
            1990: "----",
            2000: "----",
            2005: "----",
            2018: "----",
            2019: "----",
            2020: "----",
            2021: "----",
            2022: "----",
        },
        {
            key: "3",
            indicator: "Смертность на 1000 населения",
            1990: "----",
            2000: "----",
            2005: "----",
            2018: "----",
            2019: "----",
            2020: "----",
            2021: "----",
            2022: "----",
        },
        {
            key: "4",
            indicator: "Численность населения на начало года, тыс. чел.",
            1990: "----",
            2000: "----",
            2005: "----",
            2018: "----",
            2019: "----",
            2020: "----",
            2021: "----",
            2022: "----",
        },
    ];

    const columns = [
        {
            title: "Индикатор",
            dataIndex: "indicator",
            key: "indicator",
        },
        {
            title: "1990",
            dataIndex: "1990",
            key: "1990",
        },
        {
            title: "2000",
            dataIndex: "2000",
            key: "2000",
        },
        {
            title: "2005",
            dataIndex: "2005",
            key: "2005",
        },
        {
            title: "2018",
            dataIndex: "2018",
            key: "2018",
        },
        {
            title: "2019",
            dataIndex: "2019",
            key: "2019",
        },
        {
            title: "2020",
            dataIndex: "2020",
            key: "2020",
        },
        {
            title: "2021",
            dataIndex: "2021",
            key: "2021",
        },
        {
            title: "2022",
            dataIndex: "2022",
            key: "2022",
        },
    ];

    if (isFetching) {
        return <div>Loading...</div>;
    }

    for (let i = 0; i < data.length; i++) {
        if (
            indicators.includes(data[i]["Индикатор"]) &
            years.includes(data[i]["Период, год"])
        ) {
            dataSource[indicators.indexOf(data[i]["Индикатор"])][
                data[i]["Период, год"]
            ] = data[i]["Показатель"];
        }
    }

    return (
        <div className="DemoTable">
            <h3>
                Динамика демографических показателей Республики Саха (Якутия)
            </h3>
            <Table
                pagination={false}
                dataSource={dataSource}
                columns={columns}
            />
        </div>
    );
};

export default DemoTable;
